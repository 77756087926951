import { KeycloakConfig, KeycloakInitOptions, KeycloakLoginOptions } from 'keycloak-js';
import { IS_DEVELOPMENT_ENV, IS_LAB_ENV, IS_TEST_ENV, USE_LOCAL_BE } from './EnvConfig';

export const keycloakConfig: KeycloakConfig = {
  url: USE_LOCAL_BE ? 'http://localhost/auth/' : 'https://auth.afbostader.se/',
  realm: IS_DEVELOPMENT_ENV
    ? 'diremoutv'
    : IS_TEST_ENV
    ? 'diremotest'
    : IS_LAB_ENV
    ? 'diremolab'
    : 'diremo',
  clientId: 'diremo-app',
};

export const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: 'login-required',
  enableLogging: IS_DEVELOPMENT_ENV,
  checkLoginIframe: false,
};

export const keycloakLoginOptions: KeycloakLoginOptions = {
  prompt: 'none',
};
